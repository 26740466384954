import { StaticImage } from 'gatsby-plugin-image'
import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { Container } from '../components/container'
import { Layout } from '../components/layout'
import note from '../assets/images/note.svg'
import mail from '../assets/images/mail.svg'
import folderOpen from '../assets/images/folder_open.svg'
import { Trans, useTranslation } from 'react-i18next'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useCallback } from 'react'
import { JobApplication } from '../common/jobs-form.data'
import { Feedback } from '../components/feedback'
import { Input } from '../components/input'
import { FormError } from '../components/form-error'

const EmploymentPage: FC = () => {
  const { t } = useTranslation('employment')

  const methods = useForm()
  const { register, handleSubmit, control } = methods

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>()

  const submitForm = useCallback(async (values: JobApplication) => {
    setLoading(true)

    const formData = new FormData()

    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value instanceof FileList ? value[0] : value)
    }

    const response = await fetch('/php/submit-job.php', {
      method: 'post',
      body: formData,
    })
    const result = await response.json()

    setSuccess(result.success)

    setLoading(false)
  }, [])

  const cv = useWatch({ name: 'cv', control })

  return (
    <Layout title={t('title')} activePage="/jobs/">
      <Banner>
        <StaticImage
          src="../assets/images/emplois.jpg"
          alt={t('title')}
          className="image"
          layout="fullWidth"
        />
      </Banner>
      <DescriptionContainer>
        <h1>{t('title')}</h1>
        <p>{t('description.1')}</p>
        <p>{t('description.2')}</p>
        <p>{t('description.3')}</p>
      </DescriptionContainer>
      <ApplyContainer>
        <div className="desktop">
          <div className="title">
            <h2>{t('form.submitToUs')}</h2>
          </div>
          <span className="required">{t('form.required')}</span>
          <Form onSubmit={handleSubmit(submitForm)}>
            <FormProvider {...methods}>
              <FormGroup>
                <Input name="firstName" label={t('form.firstName')} />
              </FormGroup>
              <FormGroup>
                <Input name="lastName" label={t('form.lastName')} />
              </FormGroup>
              <FormGroup>
                <Input name="streetAddress" label={t('form.address')} />
              </FormGroup>
              <FormGroup>
                <Input name="city" label={t('form.city')} />
              </FormGroup>
              <FormGroup>
                <Input name="province" label={t('form.province')} />
              </FormGroup>
              <FormGroup>
                <Input name="postalCode" label={t('form.postalCode')} />
              </FormGroup>
              <FormGroup>
                <Input name="email" label={t('form.email')} />
              </FormGroup>
              <FormGroup>
                <Input name="phone" label={t('form.phone')} />
              </FormGroup>
              <FormGroup>
                <Input name="regions" label={t('form.regions')} />
              </FormGroup>

              <label className="label" htmlFor="cv">
                {t('form.addCv')}
                <span className="small-description">{t('form.cvDesc')}</span>
                <FormError name="cv" label={t('form.cv')} />
              </label>
              <label htmlFor="cv" className="field button">
                <span className="form__button">
                  {cv?.[0]?.name ? cv[0].name : t('form.selectFile')}
                </span>
              </label>
              <input
                type="file"
                id="cv"
                {...register('cv', { required: true })}
              />

              {success === undefined ? (
                <>
                  <button className="submit" type="submit">
                    <span
                      className={`form__button ${loading ? 'loading' : ''}`}
                    >
                      <span className="underline">
                        {loading ? t('form.submitting') : t('form.submit')}
                      </span>
                    </span>
                  </button>
                </>
              ) : (
                <Feedback success={success}>
                  <Trans
                    i18nKey="employment:form.feedback"
                    values={{ context: String(success) }}
                  />
                </Feedback>
              )}
            </FormProvider>
          </Form>
        </div>
      </ApplyContainer>
    </Layout>
  )
}

export default EmploymentPage

const Banner = styled.section`
  ${({ theme }) => css`
    width: 100%;
    height: 76vw;

    ${theme.media.min('tablet')} {
      height: auto;
    }

    .image {
      object-fit: cover;
      object-position: 20% 50%;
      height: 100%;
      width: 100%;
    }
  `}
`

const DescriptionContainer = styled(Container)`
  ${({ theme }) => css`
    h1 {
      color: ${theme.colors.brand};
    }
  `}
`

const ApplyContainer = styled(Container)`
  ${({ theme }) => css`
    color: ${theme.colors.white};

    ${theme.media.max('tablet_w')} {
      background: ${theme.colors.brand};
    }

    ${theme.media.min('tablet_w')} {
      padding-bottom: 5.125rem;

      .desktop {
        background: ${theme.colors.brand};
        border-radius: 2.5rem;
        padding: ${theme.spacing.containerPadding};
      }
    }

    .title {
      background: url(${note}) no-repeat left top;
      padding-left: 2.75rem;
      padding-bottom: 2rem;

      h2 {
        font-family: ${theme.typography.raleway};
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.33;
      }
    }
  `}
`

const FormGroup = styled.div``

const Form = styled.form`
  ${({ theme }) => css`
    ${theme.media.min('tablet')} {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 1.25rem;
      margin-top: 1rem;

      ${FormGroup} {
        &:nth-of-type(1) {
          grid-column: span 3;
        }
        &:nth-of-type(2) {
          grid-column: span 3;
        }
        &:nth-of-type(3) {
          grid-column: span 6;
        }
        &:nth-of-type(4) {
          grid-column: span 2;
        }
        &:nth-of-type(5) {
          grid-column: span 2;
        }
        &:nth-of-type(6) {
          grid-column: span 2;
        }
        &:nth-of-type(7) {
          grid-column: span 3;
        }
        &:nth-of-type(8) {
          grid-column: span 3;
        }
        &:nth-of-type(9) {
          grid-column: span 6;
        }
      }
      .label {
        grid-column: span 4;
      }
      .field.button {
        grid-column: span 2;
        &:hover {
          cursor: pointer;
        }
      }
      button {
        grid-column: span 6;

        &:hover {
          cursor: pointer;
        }
      }

      ${Feedback} {
        grid-column: span 6;
      }
    }

    input[type='file'] {
      visibility: hidden;
      position: absolute;
    }

    .field {
      display: block;
      width: calc(100% - 2rem);
      padding: 1rem;
      border: none;
      border-radius: 0.25rem;
      margin-bottom: 1.25rem;

      &.button {
        background: ${theme.colors.accent};
        color: ${theme.colors.brand};
        border-radius: 2rem;
        font-family: ${theme.typography.montserrat};
        font-size: 0.875rem;

        .form__button {
          padding: 0.25rem 0 0.25rem 1.75rem;
          background: url(${folderOpen}) no-repeat left top;
        }
      }
    }

    .label {
      display: block;
      width: 100%;
      padding: 1rem 0;
      line-height: 1.33;

      .small-description {
        display: block;
        color: ${theme.colors.accent};
        font-size: 0.875rem;
      }
    }

    .submit {
      background: none;
      border: none;
      width: 100%;
      margin-top: 3.75rem;

      .form__button {
        border: none;
        color: ${theme.colors.accent};
        font-family: ${theme.typography.montserrat};
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0.7rem 0 0.75rem 3.5rem;
        background: url(${mail}) no-repeat left top;
        background-position-y: 0.5rem;

        &.loading {
          opacity: 0.5;
        }
      }

      .underline {
        padding-bottom: 0.25rem;
        border-bottom: 2px solid ${theme.colors.accent};
      }
    }
  `}
`
